<template>
  <div>
    <iframe
      ref="iframe"
      @load='toChild()'
      :src="src"
      id="mobsf"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
export default {
  computed: {
    // 获取父组件的值
    src() {
      return `${this.$store.state.data_gear}/chart/pagingQuery`;
    },
  },
  mounted() {

    // this.sendMessage();

    /**
     * iframe-宽高自适应显示
     */
    function changeMobsfIframe() {
      const mobsf = document.getElementById("mobsf");
      const deviceWidth = document.body.clientWidth;
      const deviceHeight = document.body.clientHeight;
      mobsf.style.width = Number(deviceWidth) - 120 + "px"; //数字是页面布局宽度差值
      mobsf.style.height = Number(deviceHeight) - 160 + "px"; //数字是页面布局高度差
    }
    changeMobsfIframe();
    window.onresize = function () {
      changeMobsfIframe();
    };
  },
  methods:{
      /**
       * 父组件给子组件发送数据,此时这个地址为当前接收该数据的子组件的地址
       */
    toChild () {
        const mobsf = document.getElementById("mobsf");
        mobsf.contentWindow.postMessage({
            userId: this.$store.state.user.id,
        }, 'http://49.235.67.21:50401/chart/pagingQuery');
    },

  }

};
</script>
